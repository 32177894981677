exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/Homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-location-jsx": () => import("./../../../src/templates/Location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */),
  "component---src-templates-new-villa-page-jsx": () => import("./../../../src/templates/NewVillaPage.jsx" /* webpackChunkName: "component---src-templates-new-villa-page-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/Services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-villa-page-jsx": () => import("./../../../src/templates/VillaPage.jsx" /* webpackChunkName: "component---src-templates-villa-page-jsx" */)
}

